import { Button, Form, FormGroup, Image, InputGroup } from "react-bootstrap";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FaKey, FaUser, FaEnvelope } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SignUp = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const { handleSubmit } = useForm();

  const doSignUp = async () => {
    if (
      username.length === 0 ||
      password.length === 0 ||
      email.length === 0 ||
      passwordConfirm.length === 0
    ) {
      setValidated(true);
      return;
    }

    if (password !== passwordConfirm) {
      setValidated(true);
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/user/register`, {
        username,
        password,
        email,
      })
      .then((res) => {
        if (res.data.error) {
          toast(res.data.error);
        } else {
          navigate("/sign-in");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [validated, setValidated] = useState(false);

  return (
    <div className="d-flex justify-content-center align-items-center w-100 auth-panel">
      <div className="sign-up">
        <div className="logo-div d-flex">
          <Image src="./Logo.svg" className="auth-logo" alt="Logo" />

          <h2 className="__auth-title">Sign Up</h2>
        </div>

        <hr />

        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit(doSignUp)}
        >
          <FormGroup>
            <Form.Label htmlFor="su_username" className="form-label">
              User Name
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <FaUser />
              </InputGroup.Text>
              <Form.Control
                required
                type="text"
                id="su_username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="form-input"
                placeholder="Jackson"
              />
              <Form.Control.Feedback type="invalid">
                Please fill User Name.
              </Form.Control.Feedback>
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <Form.Label htmlFor="su_email" className="mt-4">
              Email Address
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <FaEnvelope />
              </InputGroup.Text>
              <Form.Control
                required
                type="email"
                id="su_email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="face@bit.com"
              />
              <Form.Control.Feedback type="invalid">
                Please fill Email Address.
              </Form.Control.Feedback>
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <Form.Label htmlFor="su_pwd" className="mt-4">
              Password
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <FaKey />
              </InputGroup.Text>
              <Form.Control
                required
                type="password"
                id="su_pwd"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="******"
              />
              <Form.Control.Feedback type="invalid">
                Please fill Password.
              </Form.Control.Feedback>
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <Form.Label htmlFor="su_pwd_confirm" className="mt-4">
              Password Confirm
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <FaKey />
              </InputGroup.Text>
              <Form.Control
                required
                type="password"
                id="su_pwd_confirm"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                placeholder="******"
              />
              <Form.Control.Feedback type="invalid">
                Please fill Password Confirm.
              </Form.Control.Feedback>
            </InputGroup>
          </FormGroup>

          <Button
            variant="primary"
            className="w-100 mt-4 auth-btn"
            size="lg"
            type="submit"
          >
            Sign Up
          </Button>
          <Button
            variant="white"
            className="w-100 mt-4 mb-4 back-btn"
            size="lg"
            style={{
              border: "solid 1px #777",
            }}
            onClick={() => navigate("/")}
          >
            Go Back
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default SignUp;
