import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Table,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import { BiMoneyWithdraw } from "react-icons/bi";
import { useEffect, useState } from "react";
// import PaginationCompoent from "../../components/pagination";
import { useAuth } from "../../hook/useAuth";
import { toast } from "react-toastify";
import axios from "axios";

const Withdraw = () => {
  const { user, balance } = useAuth();
  const [network, setNetwork] = useState("ETH");
  const [showToast, setToast] = useState(false);
  const [withdrawAddress, setWithdrawAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [withdraws, setWithdraws] = useState<any[]>([]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(withdrawAddress);
    setToast(true);
  };

  const doWithdraw = () => {
    const withdrawAmount = parseFloat(amount);
    if (!withdrawAmount) {
      toast("Please enter valid amount!");
      return;
    }

    if (withdrawAmount > balance) {
      toast("Not enough balance to withdraw");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/user/withdraw`, {
        token: window.localStorage.getItem("token"),
        amount: withdrawAmount,
        network,
        address: withdrawAddress,
      })
      .then((res) => {
        console.log(res);
      });
  };

  const getUserWithdraws = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/operation/history/withdraw/${user.id}`
      )
      .then((res) => {
        setWithdraws(res.data);
      })
      .catch((err) => {
        toast("Withdraw failed!");
      });
  };

  useEffect(() => {
    if (user.id) getUserWithdraws();
    //eslint-disable-next-line
  }, [user]);

  return (
    <Container>
      <h1>Withdraw</h1>
      <hr className="page-underline" />

      <Card>
        <Card.Body>
          <Row>
            <Col xs={4}></Col>
            <Col xs={4}>
              <Card.Title>
                <Form.Select
                  value={network}
                  onChange={(e) => setNetwork(e.target.value)}
                >
                  {/*<option value="BTC">Bitcoin (BTC)</option>*/}
                  <option value="ETH">Ethereum (ETH)</option>
                </Form.Select>
              </Card.Title>

              <div className="d-flex justify-content-between">
                <Card.Text>Time to Fund:</Card.Text>
                <Card.Text className="font-weight-bold">1 minutes</Card.Text>
              </div>
              <div className="d-flex justify-content-between">
                <Card.Text>Min. Withdraw:</Card.Text>
                <Card.Text className="font-weight-bold">
                  {network === "ETH" ? "1000 ETH_F" : "0.00000001 BTC"}
                </Card.Text>
              </div>
              <div className="d-flex justify-content-between">
                <Card.Text>Fee:</Card.Text>
                <Card.Text className="font-weight-bold">
                  Free(Transaction Fee Only)
                </Card.Text>
              </div>
              <div className="d-flex justify-content-between">
                <Card.Text>Avaliable:</Card.Text>
                <Card.Text className="font-weight-bold">
                  {balance.toFixed(2)} {network}_F
                </Card.Text>
              </div>
            </Col>
            <Col xs={4}></Col>
          </Row>
          <Row>
            <Col xs={4}></Col>
            <Col xs={4}>
              <InputGroup className="mb-2">
                <Form.Control
                  type="number"
                  aria-label="Deposit Address"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <InputGroup.Text>{network}_F</InputGroup.Text>
              </InputGroup>
              <InputGroup>
                <Form.Control
                  aria-label="Deposit Address"
                  value={withdrawAddress}
                  onChange={(e) => setWithdrawAddress(e.target.value)}
                  placeholder="Enter wallet address here..."
                />
                <InputGroup.Text>
                  <FaCopy onClick={copyToClipboard} />
                </InputGroup.Text>
              </InputGroup>
              <Button
                variant="danger"
                className="icon-btn w-100 mt-2"
                onClick={doWithdraw}
              >
                <BiMoneyWithdraw size={18} /> Withdraw
              </Button>
            </Col>
            <Col xs={4}></Col>
          </Row>

          <hr />

          <h2 className="page-sub-title">History</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th className="text-right">Amount</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {withdraws.map((withdraw, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{withdraw.amount.toFixed(2)} ETH_F</td>
                  <td>
                    {withdraw.updatedAt.substring(0, 19).replace("T", " ")}
                  </td>
                  <td>
                    <Badge bg="primary">Success</Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/*<PaginationCompoent />*/}
        </Card.Body>
      </Card>

      <ToastContainer
        className="p-3"
        position={"middle-center"}
        style={{ zIndex: 1 }}
      >
        <Toast
          onClose={() => setToast(false)}
          show={showToast}
          delay={3000}
          autohide
          animation={true}
          bg={"Success"}
        >
          <Toast.Body className="text-center">Copied to clipboard!</Toast.Body>
        </Toast>
      </ToastContainer>
    </Container>
  );
};

export default Withdraw;
