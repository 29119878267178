import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Hero = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Disable next and prev buttons
    fade: true,
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="d-flex">
            <img src="/Logo.svg" alt="" />
            <h1> FaceBIT</h1>
          </div>
          <nav>
            <a href="#Comparison">How To Play</a>
            <a href="#why-we-are">Why We Are</a>
            <a href="/home">Start Playing</a>
          </nav>
        </div>
      </header>
      <Slider {...settings} className="slider-content">
        <div className="slider-section">
          <div className="landingImage">
            <img
              src="/mainBG4.webp"
              alt=""
              style={{
                objectPosition: "center bottom",
              }}
            />
            <span className="mask-span-2" />
          </div>

          <div className="row h-100">
            <div className="col col-md-12 d-flex flex-column">
              <div className="mt-30 mb-auto d-flex flex-column">
                <label
                  className="ml-auto mr-auto text-obj-3"
                  data-heading="Origin Rules, Better Condition"
                >
                  Origin Rules, Better Condition
                </label>
                <span className="text-obj-2 mt-5 text-center">
                  Start journey with origin,secure and better conditional crash
                  game
                </span>
                <a
                  className="animated-button8 mt-5"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  href="/home"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  Start Playing
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-section">
          <div className="landingImage">
            <img src="/mainBG3.webp" alt="" />
            <span className="mask-span" />
            <span className="mask-span-2" />
          </div>

          <div className="row h-100 glass-backdrop">
            <div className="col col-md-1 d-flex" />
            <div className="col col-md-3 d-flex">
              <img
                src="/astro.png"
                alt=""
                className="section-obj-1 mt-auto mb-auto"
                style={{
                  transform: "scaleX(-1)",
                }}
              />
            </div>

            <div className="col col-md-1 d-flex" />
            <div className="col col-md-6 d-flex flex-column">
              <div className="mt-30 mb-auto d-flex flex-column">
                <label
                  className="text-obj-1"
                  data-heading="Cash Out Before the Crash!"
                >
                  Cash Out Before the Crash!
                </label>
                <span className="text-obj-2 mt-5">
                  Compete with others in real-time and see who cashes out at the
                  perfect moment.
                </span>
              </div>
            </div>
            <div className="col col-md-1 d-flex" />
          </div>
        </div>
        <div className="slider-section overflow-hidden">
          <div className="landingImage">
            <img src="/landing2.jpg" alt="" />
            <span className="mask-span-2" />
          </div>
          <div className="row h-100">
            <div className="col col-md-8">
              <img src="/wukong_logo.png" className="section-obj-3" alt="" />
              <div className="d-flex flex-column">
                <label className="text-obj-4">Special for Launch</label>
                <span className="text-obj-2 mt-5">
                  To celebrate the highly anticipated release of Black Myth:
                  Wukong, we're offering exclusive benefits to our players! Join
                  us during this special event and enjoy!
                </span>
              </div>
            </div>
            <div className="col col-md-4 d-flex">
              <img
                src="/Wukong.webp"
                alt=""
                className="section-obj-2 mt-auto"
              />
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
};

export default Hero;
