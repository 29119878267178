const Copyright = () => {
  return (
    <footer>
      <div
        className="container"
        style={{
          maxWidth: "-webkit-fill-available",
          borderTop: "1px solid #444",
        }}
      >
        <p>&copy; 2024 Face BIT. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Copyright;
