import { Route, Routes, Navigate } from "react-router-dom";

import Landing from "./pages/landing/landing";
import Deposit from "./pages/deposit";
import Withdraw from "./pages/withdraw";
import SignIn from "./pages/signin";
import SignUp from "./pages/signup";
import Home from "./pages/home";
import { useAuth } from "./hook/useAuth";
import { useMemo } from "react";

const SecureRoutes = () => {
  const { isLoggedIn } = useAuth();
  const publicRoutes = [
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "/home",
      element: <Home />,
    },

    {
      path: "/sign-in",
      element: <SignIn />,
    },
    {
      path: "/sign-up",
      element: <SignUp />,
    },
  ];

  const protectedRoutes = useMemo(
    () => [
      {
        path: "/deposit",
        element: isLoggedIn ? <Deposit /> : <Navigate to="/sign-in" />,
      },
      {
        path: "/withdraw",
        element: isLoggedIn ? <Withdraw /> : <Navigate to="/sign-in" />,
      },
    ],
    [isLoggedIn]
  );
  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route path={route.path} element={route.element} />
      ))}
      {protectedRoutes.map((route) => {
        console.log(isLoggedIn);
        return <Route path={route.path} element={route.element} />;
      })}
    </Routes>
  );
};

export default SecureRoutes;
