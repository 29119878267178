import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, FormGroup, Image, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaUser, FaKey } from "react-icons/fa";

const SignIn = () => {
  const navigate = useNavigate();
  const { handleSubmit } = useForm();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [authResult, setAuthResult] = useState(true);

  const doSignIn = async () => {
    if (username.length === 0 || password.length === 0) {
      setValidated(true);
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/user/login`, {
        username,
        password,
      })
      .then((result) => {
        window.localStorage.setItem("token", result.data.access_token);
        window.localStorage.setItem("user", JSON.stringify(result.data.user));
        window.location.href = "/home";
      })
      .catch((error) => {
        setAuthResult(false);
        console.log(error);
      });
  };

  const [validated, setValidated] = useState(false);

  return (
    <div className="d-flex justify-content-center align-items-center w-100 auth-panel">
      <div className="sign-up">
        <div className="logo-div d-flex">
          <Image src="./Logo.svg" className="auth-logo" alt="Logo" />

          <h2 className="__auth-title mint">Sign In</h2>
        </div>

        <hr />

        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit(doSignIn)}
        >
          <FormGroup>
            <Form.Control.Feedback
              type="invalid"
              className={`${authResult ? "d-none" : "d-block"}`}
            >
              Credentials is incorrect.
            </Form.Control.Feedback>
            <Form.Label htmlFor="su_username" className="form-label">
              User Name
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <FaUser />
              </InputGroup.Text>
              <Form.Control
                required
                type="text"
                id="su_username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="User Name"
              />
              <Form.Control.Feedback type="invalid">
                Please fill User Name.
              </Form.Control.Feedback>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Form.Label htmlFor="su_pwd" className="form-label mt-4">
              Password
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <FaKey />
              </InputGroup.Text>
              <Form.Control
                required
                type="password"
                id="su_pwd"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <Form.Control.Feedback type="invalid">
                Please fill Password.
              </Form.Control.Feedback>
            </InputGroup>
          </FormGroup>
          <Button
            variant="primary"
            className="w-100 mt-4 auth-btn"
            size="lg"
            type="submit"
          >
            Sign In
          </Button>

          <Button
            variant="white"
            className="w-100 mt-4 mb-4 back-btn"
            size="lg"
            style={{
              border: "solid 1px #777",
            }}
            onClick={() => navigate("/")}
          >
            Go Back
          </Button>

          <Form.Text className="text-black">
            You don't have account, please{" "}
            <Link to="/sign-up" className="w-100 text-center">
              Sign Up
            </Link>
          </Form.Text>
        </Form>
      </div>
    </div>
  );
};

export default SignIn;
