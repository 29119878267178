import { useMemo, useRef, useState } from "react";
import { useGameHistory } from "../../../hook/useGameHistory";
import { useWebsocket } from "../../../hook/useWebsocket";
import Panel from "../../../layout/panel";
import SimpleBar from "simplebar-react";
import { useAuth } from "../../../hook/useAuth";
import { Button, Modal } from "react-bootstrap";
import React from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import { TooltipItem } from "chart.js";
import "chart.js/auto";
interface Props {
  className?: string;
}

const Users = ({ className }: Props) => {
  const { user } = useAuth();
  const { rate } = useWebsocket();
  const { betList } = useGameHistory();

  const betResult = useMemo(() => {
    const cashedResults = betList.filter((x) => x.bust <= rate);
    const otherResult = betList.filter((x) => x.bust > rate);
    return [...otherResult, ...cashedResults.sort((a, b) => b.bust - a.bust)];
  }, [betList, rate]);

  const [data, setData] = useState<any>({
    list: [],
    user: {},
  });
  const [modalShow, setModalShow] = React.useState(false);
  const chartRef = useRef();

  const showTradeHistory = (userId: string) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/history/recent`, {
        params: {
          userId: userId,
        },
      })
      .then((result) => {
        setModalShow(true);
        setData(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const customToolTipBody = (tooltipItems: TooltipItem<any>[]) => {
    return `Amt: ${data.list[tooltipItems[0].parsed.x].amount}, Bust: ${
      data.list[tooltipItems[0].parsed.x].bust
    }`;
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          footer: customToolTipBody,
        },
      },
    },
  };

  const chartData = useMemo(() => {
    const chartDts: any = {
      labels: [],
      datasets: [
        {
          label: "",
          data: [],
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "transparent",
          fill: false,
          pointRadius: 2,
          borderWidth: 3,
        },
      ],
    };
    for (let i = 0; i < data.list.length; i++) {
      chartDts.labels.push(`${data.list[i].gameId}`);
      chartDts.datasets[0].data.push(`${data.list[i].profit}`);
    }
    return chartDts;
  }, [data]);

  return (
    <Panel className={`${className ?? ""} align-items-top dark-panel ml-15`}>
      <SimpleBar className="sticky-table height-available">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>@</th>
              <th>Bet</th>
              <th>Profit</th>
            </tr>
          </thead>
          <tbody>
            {betResult.map((value, index) => (
              <tr
                key={`bet_${index}`}
                className={`${value.bust <= rate ? "cashed" : ""} ${
                  user.userId === value.user ? "mine" : ""
                }`}
              >
                <td>
                  <Button
                    variant="link"
                    className="text-decoration-none history_user_link"
                    onClick={() => showTradeHistory(value.user)}
                  >
                    {value.user}
                  </Button>
                </td>
                <td>{value.bust <= rate ? value.bust : "-"}</td>
                <td>{value.amount}</td>
                <td>
                  {value.bust <= rate
                    ? ((value.bust - 1) * value.amount).toFixed(2)
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </SimpleBar>

      <Modal
        show={modalShow}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => setModalShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="modal-title"
          >
            {data.user.userId ? data.user.userId : "Recent Trade History"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Line ref={chartRef} data={chartData} options={chartOptions} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Panel>
  );
};

export default Users;
