const Competitive = () => {
  return (
    <section id="Comparison">
      <h1>How To Play</h1>
      <ul className="StepProgress">
        <li className="StepProgress-item is-done">
          <span className="item-tag" />
          <strong>Place Your Bet</strong>
          Start by deciding how much you want to wager. You can adjust your bet
          amount by selecting from the available options or entering a custom
          amount. Remember, the amount you bet will determine your potential
          winnings, so choose wisely based on your strategy.
        </li>
        <li className="StepProgress-item is-done">
          <span
            className="item-tag"
            style={{
              borderColor: "#8b3ac9",
            }}
          />
          <strong>Watch the Multiplier Rise</strong>
          Once the round begins, the multiplier starts climbing. Your goal is to
          cash out at the highest possible multiplier before the crash occurs.
          The multiplier can rise rapidly, but it can crash at any moment, so
          keep your eyes on the screen!
        </li>
        <li className="StepProgress-item current">
          <span
            className="item-tag"
            style={{
              borderColor: "#a03abc",
            }}
          />
          <strong>Cash Out Before the Crash</strong>
          Timing is everything. Click the "Cash Out" button at any time during
          the round to lock in your winnings at the current multiplier. The
          longer you wait, the higher the multiplier—and your potential
          winnings—but the risk also increases.
        </li>
        <li className="StepProgress-item">
          <span
            className="item-tag"
            style={{
              borderColor: "#b439b0",
            }}
          />
          <strong>Collect Your Winnings</strong>
          If you cash out before the crash, congratulations! Your winnings will
          be calculated based on your bet amount multiplied by the current
          multiplier. The funds will be immediately added to your balance, ready
          for the next round.
        </li>
        <li className="StepProgress-item">
          <span
            className="item-tag"
            style={{
              borderColor: "#c839a4",
            }}
          />
          <strong>Try Again or Adjust Your Strategy</strong>
          After the round ends, a new one begins almost instantly. Use what
          you've learned from previous rounds to refine your strategy. Do you
          play it safe and cash out early, or do you take a risk and hold out
          for a higher multiplier? The choice is yours!
        </li>
      </ul>
    </section>
  );
};

export default Competitive;
