import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
const WhyWeAre = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const settings = {
    dots: false,
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Disable next and prev buttons
    swipe: true, // Enable swiping
    afterChange: (current: number) => setActiveSlide(current),
  };

  const content = [
    {
      img: "/secure.png",
      title: "Trustworthy",
      content:
        "We use a provably fair system to ensure transparency and fairness in every game.",
    },
    {
      img: "/exciting.png",
      title: "Exciting",
      content:
        "Experience the thrill of watching the multiplier grow and making strategic cashouts.",
    },
    {
      img: "/web.png",
      title: "Innovative",
      content:
        "Our platform offers the latest technology and user-friendly interface to enhance your gaming experience.",
    },
  ];

  return (
    <section id="why-we-are">
      <img src="/pxfuel.jpg" alt="" className="background" />
      <h1>Why We Are</h1>
      <div className="slider-container">
        <Slider {...settings}>
          {content.map((item, index) => (
            <div
              className={`slide reason ${
                (index + 2) % content.length === activeSlide ? "active" : ""
              }`}
            >
              <div className="image-area">
                <img src={item.img} alt="Trustworthy" />
              </div>

              <h3>{item.title}</h3>
              <p>{item.content}</p>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default WhyWeAre;
