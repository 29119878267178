import {
  Badge,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Table,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import { QRCode } from "react-qrcode-logo";
import { useEffect, useMemo, useState } from "react";
// import PaginationCompoent from "../../components/pagination";
import axios from "axios";
import { DepositInfo } from "../../const/interfaces";
import { useAuth } from "../../hook/useAuth";

const Deposit = () => {
  const [showToast, setToast] = useState(false);
  const [depositAddress, setDepositAddress] = useState<DepositInfo[]>([]);
  const [network, setNetwork] = useState("ETH");
  const [deposits, setDeposits] = useState<any[]>([]);
  const { user } = useAuth();

  const address = useMemo(
    () => depositAddress.find((x) => x.network === network)?.address ?? "",
    [depositAddress, network]
  );

  const copyToClipboard = () => {
    navigator.clipboard.writeText(address);
    setToast(true);
  };

  const getUserWallet = () => {
    const token = window.localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/wallet`, {
        params: {
          token,
        },
      })
      .then((res) => {
        if (res.data) setDepositAddress(res.data);
      });
  };

  const getUserDeposits = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/operation/history/deposit/${user.id}`
      )
      .then((res) => {
        setDeposits(res.data);
      });
  };

  useEffect(() => {
    getUserWallet();
    if (user.id) getUserDeposits();
    // eslint-disable-next-line
  }, [user]);
  return (
    <Container>
      <h1>Deposit</h1>
      <hr className="page-underline" />

      <Card>
        <Card.Body>
          <Row>
            <Col xs={4}></Col>
            <Col xs={4}>
              <Card.Title>
                <Form.Select
                  value={network}
                  onChange={(e) => setNetwork(e.target.value)}
                >
                  {/*<option value="BTC">Bitcoin (BTC)</option>*/}
                  <option value="ETH">Ethereum (ETH)</option>
                </Form.Select>
              </Card.Title>
            </Col>
            <Col xs={4}></Col>
          </Row>
          <Row>
            <Col xs={4}></Col>
            <Col xs={4}>
              <div className="qr-code-panel">
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={address}
                  logoImage={`/${network}.svg`}
                  logoWidth={network === "BTC" ? 60 : 30}
                  logoHeight={60}
                  removeQrCodeBehindLogo
                  logoPadding={5}
                  logoPaddingStyle="circle"
                />
              </div>
              <InputGroup>
                <Form.Control
                  aria-label="Deposit Address"
                  value={address}
                  disabled
                />
                <InputGroup.Text>
                  <FaCopy onClick={copyToClipboard} />
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col xs={4}></Col>
          </Row>
          <Row>
            <Col className="deposit-description">
              <span>
                Thank you for choosing us for your deposit needs. At the moment,
                we currently support <strong>ETH</strong> deposits only. <br />
                In the coming days, we will be expanding our offerings to
                support <strong>BTC</strong>, <strong>BNB</strong>, and{" "}
                <strong>USDT</strong>. <br />
                For your reference, the current conversion rate is:{" "}
                <strong>1 ETH = 10,000 ETH_F</strong>
                <br />
                We appreciate your patience and look forward to providing you
                with more deposit options soon. Stay tuned for updates!
              </span>
            </Col>
          </Row>
          <hr />

          <h2 className="page-sub-title">History</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th className="text-right">Amount</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {deposits.map((deposit, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{deposit.amount.toFixed(2)} ETH_F</td>
                  <td>
                    {deposit.updatedAt.substring(0, 19).replace("T", " ")}
                  </td>
                  <td>
                    <Badge bg="primary">Success</Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* <PaginationCompoent /> */}
        </Card.Body>
      </Card>

      <ToastContainer
        className="p-3"
        position={"middle-center"}
        style={{ zIndex: 1 }}
      >
        <Toast
          onClose={() => setToast(false)}
          show={showToast}
          delay={1000}
          autohide
          animation={true}
          bg={"Success"}
        >
          <Toast.Body className="text-center text-dark">
            Copied to clipboard!
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </Container>
  );
};

export default Deposit;
