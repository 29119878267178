import { LuRocket } from "react-icons/lu";
import { AiTwotoneBank, AiOutlineMoneyCollect } from "react-icons/ai";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { MdLogout, MdLogin } from "react-icons/md";
import { useAuth } from "../hook/useAuth";
import { Link, useLocation } from "react-router-dom";

const Menu = () => {
  const { pathname } = useLocation();
  const { balance } = useAuth();
  const iconSize = 30;
  const menus = [
    {
      title: "Play",
      icon: <LuRocket size={iconSize} />,
      url: "/home",
    },
    {
      title: "Deposit",
      icon: <AiTwotoneBank size={iconSize} />,
      url: "/deposit",
    },
    {
      title: "Withdraw",
      icon: <AiOutlineMoneyCollect size={iconSize} />,
      url: "/withdraw",
    },
  ];

  const blackList = ["/", "/sign-in", "/sign-up"];

  const { isLoggedIn, logout, user } = useAuth();
  return blackList.includes(pathname) ? (
    <></>
  ) : (
    <div className="menu">
      <Link className="logo mb-10 text-decoration-none" to="/">
        <img src="./Logo.svg" className="logo" alt="" />
        <label>Face BIT</label>
      </Link>
      {menus.map((item, index) => (
        <Link to={item.url} className="menu-item" key={`menu_${index}`}>
          {item.icon}
          <label>{item.title}</label>
        </Link>
      ))}
      {isLoggedIn ? (
        <>
          <Link className="menu-item mt-auto" to="/sign-in" onClick={logout}>
            <MdLogout size={iconSize} />
            <label>Sign Out</label>
          </Link>
          <Link className="avatar" to="/profile">
            <HiOutlineUserCircle size={iconSize * 1.7} color="#fff" />
            <div className="user-brief">
              <label> {user.userId} </label>
              <span> {balance.toFixed(5)} </span>
            </div>
          </Link>
        </>
      ) : (
        <Link className="menu-item mt-auto" to="/sign-in">
          <MdLogin size={iconSize} />
          <label>Sign In</label>
        </Link>
      )}
    </div>
  );
};

export default Menu;
